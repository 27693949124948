@import "../_variables.scss";

.mlp-carousel-cards {
    @include media-breakpoint-down(sm) {
        .sg-card__title {
            min-height: 38px;
        }
    }
}

.mlp-carousel-buttons {
    .sg-btn {
        margin-top: 30px;
    }
}
